.balloon {
  display: flex;
  max-width: 305px;
}

.content {
  align-self: center;
}

.icon {
  margin-right: 8px;
  display: flex;
  width: 32px;
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

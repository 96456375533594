.wrapper {
  --light-surface-neutral: #f3f6ff;

  display: flex;
  flex-direction: column;
  height: 500px;
  background: var(--light-surface-neutral);
  align-items: center;
  justify-content: center;
}

.icon {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 4px;
}

.message {
  margin-bottom: 40px;
}

@keyframes slidein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.container {
  position: relative;
  width: 40px;
  height: 50px;
}

.pin {
  position: relative;
  margin: 0 auto;
  width: 14px;
  height: 14px;
  background-color: var(--accent-positive-primary);
  border-radius: 50%;
  animation-name: slidein;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;

  &::before {
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 50%;
    width: 18px;
    height: 8px;
    content: '';
    background-color: var(--black_10);
    border-radius: 50%;
    transform: translate(-50%, -60%);
  }
}

.label {
  position: relative;
  z-index: 1;
  padding: 4px;
  width: 40px;
  height: 40px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-origin: content-box;
  background-size: contain;
  border-radius: 4px;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
  transform: translate(0, 2px);
}

.wrapper {
  margin-bottom: 15px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
}

.highway-icon,
.metro-icon {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.travel-icon {
  margin-right: 8px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .item + .item {
    margin-top: 4px;
  }
}

@media (min-width: 1025px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    margin-right: 24px;
    margin-bottom: 4px;
  }
}

.balloon {
  display: flex;
  max-width: 270px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
}

.icon,
.title {
  text-decoration: none;
}

.icon {
  margin-right: 8px;
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
}

.title,
.min-price {
  color: var(--decorative-theme-dark);
  transition: color 0.2s ease-in-out;
}

.title {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title:hover {
  color: var(--accent-main-primary);
}

.roominess {
  color: #747a8d;
}

.roominess::before {
  content: ' ';
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 24px 0 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  background-color: var(--decorative-theme-white);
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: var(--gray10_100);
  border-radius: 4px 0 0 4px;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  width: 0;
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
}

.header,
.tabs-wrapper {
  padding: 0 16px;
}

.tabs-wrapper {
  margin-top: 16px;
}

.content-wrapper {
  margin-top: 24px;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.data-from {
  margin-top: auto;
  margin-bottom: 16px;
}

.fixed {
  position: fixed;
  z-index: 99999;
}

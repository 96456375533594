.container {
  padding: 16px 16px 16px 12px;
  display: flex;
  min-height: 72px;
  background-color: #f3f6ff;
  border-radius: 4px;
}

.horizontal-margin {
  margin: 0 16px;
}

.icon-wrapper {
  margin-right: 12px;
}

.clickable {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 8px;
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  width: 104px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  color: white;
  background: rgba(255, 255, 255, 0.2);
  /* stylelint-disable-next-line declaration-no-important */
  border: none !important;
  border-radius: 4px;
  align-items: center;
}

.button:hover {
  background: rgba(255, 255, 255, 0.18);
}

.department-link-wrapper {
  margin-bottom: 32px;
}

.rate-mark-wrapper {
  margin-bottom: 24px;
}

.terms {
  margin: 0;
  padding: 0;
  list-style: none;
}

.term-item {
  position: relative;
  padding: 8px 0 8px 24px;
  color: var(--accent-negative-primary);
}

.term-item--done {
  color: var(--decorative-theme-dark);
}

.term-item::before {
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background-image: url('./assets/clear.svg');
}

.term-item--done::before {
  background-image: url('./assets/done.svg');
}

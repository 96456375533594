.row {
  padding: 0 16px;
  display: flex;
  width: 100%;
  user-select: none;
  background-color: white;
  border: none;
  outline: 0;
  box-shadow: none;
  align-items: center;
}

.row.has-hover {
  cursor: pointer;
}

.row.has-hover:hover {
  background-color: #f3f6ff;
}

.row .text {
  margin-left: 8px;
  padding: 12px 0;
  flex: auto;
  display: flex;
}

.row:not(:last-child) .text {
  border-bottom: 1px solid var(--gray10_100);
}

.success-icon-wrapper {
  margin-left: auto;
  flex: none;
  align-self: flex-end;
}

.wrapper {
  position: absolute;
  bottom: 24px;
}

.wrapper.fullscreen {
  position: fixed;
  z-index: 10001;
  transform: translateX(-50%);
}

.balloon {
  display: flex;
  max-width: 305px;
}

.icon {
  flex: none;
  margin-right: 8px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.route {
  margin: 4px 0;
}

.route-button {
  margin: 0;
  padding: 0;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--accent-main-primary);
  background-color: transparent;
  border: 0;
  outline: none;

  &:focus,
  &:active {
    color: var(--control-main-primary-pressed);
  }

  &:hover {
    color: var(--control-main-primary-hovered);
  }
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 16px 16px;
  background-color: var(--decorative-theme-white);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--gray10_100);
  border-bottom-right-radius: 4px;
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
}

.warning {
  margin-top: 4px;
  margin-bottom: 24px;
}

.years {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
}

.fixed {
  position: fixed;
  z-index: 99999;
}

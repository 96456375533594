.department-link-wrapper {
  margin-bottom: 32px;
}

.rate-mark-wrapper {
  margin-bottom: 16px;
}

.terms {
  display: grid;
  grid-template-areas: 'label indicator rate';
  grid-template-columns: minmax(min-content, auto) minmax(auto, 169px) max-content;
  align-items: center;
  gap: 0 8px;
}

.label {
  grid-area: 'label';
  padding: 8px 0;
}

.indicator {
  grid-area: 'indicator';
}

.rate {
  grid-area: 'rate';
  text-align: right;
}

.container {
  display: flex;
  column-gap: 8px;
  align-items: flex-end;
}

.rate {
  font-family: Lato, sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  color: var(--decorative-theme-dark);
}

.rate-from {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  color: var(--decorative-theme-dark);
}

.map {
  position: relative;
}

.map::before {
  padding-bottom: 180px;
  display: block;
  content: '';
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pin {
  position: absolute;
  left: calc(50% - 27px);
  bottom: 50%;
  width: 54px;
  height: 54px;
}

.modal {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

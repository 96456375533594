.wrapper {
  position: absolute;
  bottom: 24px;
  width: 100%;
  white-space: nowrap;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.fixed {
  position: fixed;
  z-index: 99999;
  max-width: 100%;
}

.item {
  padding: 8px 18px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  background: white;
  border: 1px solid #ced1d7;
  border-radius: 4px;
}

.item input {
  display: none;
}

.checked {
  background: var(--gray6_100);
  border: 1px solid var(--decorative-theme-dark);
}

.item span {
  margin-left: 10px;
}

.future-type-icon {
  margin-right: -4px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .wrapper {
    bottom: 0;
    padding: 16px 20px 20px;
    background-color: white;
  }
}

@media (min-width: 1025px) {
  .wrapper {
    left: 24px;
    max-width: calc(100% - 88px - 24px);
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.text {
  display: flex;
  width: 100%;
}

.info-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rate {
  margin-left: auto;
}

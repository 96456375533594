.container {
  overflow: hidden;
  height: 12px;
  background: var(--decorative-theme-white);
  border-radius: 8px;
}

.rate {
  height: 100%;
  background-color: var(--accent-main-primary);
  border-radius: 8px;
  transition: width 0.3s ease-in;
}

.label {
  position: absolute;
  padding: 0 8px;
  display: flex;
  width: auto;
  height: 25px;
  text-align: center;
  white-space: nowrap;
  background-color: var(--decorative-theme-white);
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;
}

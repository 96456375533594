.container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.title {
  margin-bottom: 24px;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 500px;

  &-mobile {
    height: auto;
  }
}

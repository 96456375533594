.container {
  overflow: hidden;
  height: 12px;
  background: #e6f0ff;
  border-radius: 8px;
}

.rate {
  height: 100%;
  background-color: var(--accent-main-primary);
  border-radius: 8px;
}

@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  display: flex;
  width: 100%;
  height: 500px;
  background-color: var(--gray6_100);
  align-items: center;
  justify-content: center;
}

.wrapper {
  position: absolute;
  z-index: 10;
  top: 24px;
  left: 24px;
}

.fixed {
  position: fixed;
  z-index: 99999;
}

.container {
  padding: 16px 0 8px;
  min-width: 316px;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
}

.title-row {
  margin-bottom: 8px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.title-row .chevron-icon {
  margin-left: auto;
}

.content {
  overflow: hidden;
  transition: max-height 0.3s;
}

.row {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.row .text {
  margin-left: 8px;
  padding: 12px 0;
  flex: auto;
}

.row:not(:last-child) .text {
  border-bottom: 1px solid var(--gray10_100);
}

@keyframes slidein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.pin {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 20px;
  animation-name: slidein;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}

.square {
  border: none;
  border-radius: 4px;
}

.blue {
  background-color: var(--accent-main-primary);
}

.green {
  background-color: var(--accent-positive-primary);
}

.pin_main {
  position: relative;
  width: 54px;
  height: 54px;
  background-image: url('./assets/pin.svg');
  animation-name: slidein;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}

.badge {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  background-color: var(--accent-positive-primary);
  border: 1px white solid;
  border-radius: 50%;
}

.arrow {
  position: absolute;
  display: flex;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: white;
  border: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
}

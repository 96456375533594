.pin {
  position: relative;
  display: inline-flex;
}

.balloon {
  position: relative;
  padding: 2px;
  display: flex;
  min-width: 60px;
  height: 26px;
  font-size: var(--fontSize_16px);
  font-weight: bold;
  line-height: var(--lineHeight_22px);
  background: var(--decorative-theme-white);
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.price {
  padding-right: 2px;
  padding-left: 4px;
  white-space: nowrap;
}

.count {
  padding: 0 5px;
  background: var(--gray6_100);
  border-radius: 4px;
}

.point {
  position: absolute;
  left: calc(50% - 6px);
  bottom: -9px;
  width: 12px;
  height: 12px;
  background-color: var(--accent-main-primary);
  border-radius: 6px;
}

.wrapper {
  top: 24px;
  right: 72px;

  &-mobile {
    top: initial;
    right: 24px;
    bottom: calc(50% - 90px);
  }
}

.fixed {
  position: fixed;
  z-index: 99999;
}

.normal {
  position: absolute;
}

.button {
  position: relative;
  padding: 8px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  white-space: nowrap;
  cursor: pointer;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.95);

  &-mobile {
    padding: 0;
    min-width: 40px;
  }
}

.button:hover {
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.33);
}

.button > span {
  margin-left: 8px;
}

.close-button {
  position: fixed;
  z-index: 99999;
  top: 24px;
  right: 24px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
